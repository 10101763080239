import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { addTab } from "../../../actions/LayoutAction";
import { setSelectedAttribute } from "../../../actions/SelectedAttributeAction";

import { MapContext } from "../Map";

import Select from "ol/interaction/Select";

export class SearchTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchContent: ""
    };
    this.mapConfig = this.props.mapConfig;
  }

  onChange = event => {
    this.setState({ searchContent: event.target.value });
  };

  getCenterOfExtent = Extent => {
    var X = Extent[0] + (Extent[2] - Extent[0]) / 2;
    var Y = Extent[1] + (Extent[3] - Extent[1]) / 2;
    return [X, Y];
  };
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  }

  search = () => {
    console.log("start search ws");
    let selectedWorksite = undefined;
    // search in the worksite store for the feature
    this.props.worksites.list.features.forEach(item => {
      if (item.properties.id_ws == this.state.searchContent) {
        selectedWorksite = item;
      }
    });

    if (selectedWorksite !== undefined) {
      // get the feature from source layer (to avoid the problem that the feature stored in the redux is not a OL feature)
      let selectedFeature;
      this.context.getLayers().forEach(layer => {
        if (layer.get("title") === "Chantiers") {
          selectedFeature = layer.getSource().getFeatureById(selectedWorksite.id);
        }
        console.log(selectedFeature);

      });

      // set the feature as selected on the map
      // let selectInteraction = new Select();
      // this.context.addInteraction(selectInteraction);
      this.context.getInteractions().forEach((element) => {
        if (element.get("name") === "selectFeature") {
          element.getFeatures().clear();
          element.getFeatures().push(selectedFeature);
        }
      })
      //selectInteraction.getFeatures().push(selectedFeature);

      // set the selected worksite in the store with the corresponding key of the map.json
      let selectedFeatureObj = {};
      console.log(selectedFeature);
      Object.keys(this.mapConfig.attributes.keys).forEach(
        function (key) {
          const keyDB = this.mapConfig.attributes.keys[key];
          const valueViewer = selectedFeature.get(keyDB);
          selectedFeatureObj[key] = valueViewer;
        }.bind(this)
      );
      // console.log(selectedFeatureObj);
      // console.log("center on the feature");
      // console.log(this.getCenterOfExtent(selectedFeature.getGeometry().getExtent()))

      // fit map to extend geometry
      // let coord = this.getCenterOfExtent(selectedFeature.getGeometry().getExtent());
      // this.context.getView().setCenter(coord);
      this.context.getView().fit(selectedFeature.getGeometry().getExtent());

      // update store
      this.props.setSelectedAttribute(selectedFeatureObj);
      this.props.addTab("attributeTab");
    }
  };

  render() {
    // load config file
    var appConfig = this.props.mapConfig;
    return (
      <div
        className={
          this.props.isAttributeTabVisible
            ? "search__container--active"
            : "search__container"
        }
      >
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder={appConfig.searchTab.placeholder}
            aria-label={appConfig.searchTab.placeholder}
            aria-describedby="search-btn"
            value={this.state.searchContent}
            onChange={this.onChange}
            onKeyDown={this._handleKeyDown}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary gate__btn--search"
              type="button"
              id="search-btn"
              onClick={this.search}
            >
              <i className="fa fa-search" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SearchTab.propTypes = {
  addTab: PropTypes.func.isRequired,
  setSelectedAttribute: PropTypes.func.isRequired,
  worksites: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  worksites: state.worksites
});

const mapDispatchToProps = dispatch => ({
  addTab: tab => dispatch(addTab(tab)),
  setSelectedAttribute: item => dispatch(setSelectedAttribute(item))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchTab);

SearchTab.contextType = MapContext;
